import { faBinoculars, faCheck, faDollarSign, faFireAlt, faMicrophone, faMoon, faStar, faTemperatureHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";
import Badge from "../../components/Badge";
import { CamaraCard, CameraCardImagePosition } from "../../components/camaras/CameraCard";
import MainLayout from "../../layout/MainLayout";

/**
 * TODO:
 *  - Añadir cámaras: D26 Domo, S16, C26
 */

const CamarasInterioresPage = () => {
    return (
        <MainLayout title="Cámaras interiores">
            <h1 className="text-5xl">Cámaras de videovigilancia de interior</h1>

            <div className="pb-6">
                <p className="whitespace-pre-line">Las cámaras de <strong>videovigilancia interior</strong> de MOBOTIX&copy; cuentan con protección <OutboundLink title="Ver artículo de Wikipedia sobre la protección IP" href="https://es.wikipedia.org/wiki/Grado_de_protecci%C3%B3n_IP" target="_blank" rel="noopener noreferrer"><em>IP66</em></OutboundLink> para poder resistir cualquier tipo de entorno adverso: lluvia, nieve o sol.</p>
                <p className="whitespace-pre-line">También cuentan con un <strong>almacenamiento local</strong> (con tarjeta MicroSD interna) para poder guardar grabaciones, aunque también es posible conectarlas a un <OutboundLink title="Ver artículo de Wikipedia sobre los NAS" href="https://es.wikipedia.org/wiki/Almacenamiento_conectado_en_red" target="_blank" rel="noopener noreferrer"><em>NAS</em></OutboundLink> en tu propia red o subirlas a la nube.</p>
            </div>

            <div className="grid gap-4">
                <CamaraCard
                    image="d26.jpg"
                    imageAlt="Cámara MOBOTIX&copy; D26 Dome"
                >
                    <div className="pt-6 md:p-8 text-center md:text-left space-y-4">

                        <div className="flex flex-row items-center">
                            <h3 className={`text-2xl font-bold mb-2`}>
                                D26 Dome
                            </h3>
                        </div>

                        <blockquote>
                            <p className="font-sem whitespace-pre-line">
                                Un sistema de vídeo IP, inteligente y compacto, en una clásica cámara domo con lente orientable manualmente y resistente a la intemperie bajo una cúpula de policarbonato de 1,5 mm resistente a los impactos (IK10). La MOBOTIX&copy; D26 dispone de la misma plataforma pionera de sistema que las demás cámaras de la última generación tecnológica x26/x16 de MOBOTIX&copy;.
                            </p>
                        </blockquote>
                    </div>
                </CamaraCard>

                <CamaraCard
                    image="s16.jpg"
                    imageAlt="Cámara MOBOTIX&copy; S16 DualFlex"
                    imagePosition={CameraCardImagePosition.Right}
                >
                    <div className="pt-6 md:p-8 text-center md:text-left space-y-4">

                        <div className="flex flex-row items-center">
                            <h3 className={`text-2xl font-bold mb-2`}>
                                S16 DualFlex
                            </h3>
                        </div>

                        <blockquote>
                            <p className="font-sem whitespace-pre-line">
                                Con la S16, MOBOTIX ofrece un sistema resistente a la intemperie que se caracteriza sobre todo por sus posibilidades de montaje extremadamente flexibles. Los dos módulos de sensor se unen a la carcasa, montada de forma que quede oculta, por medio de un cable de conexión de hasta tres metros de longitud. De este modo se pueden vigilar de forma discreta dos espacios contiguos con una sola cámara.
                            </p>
                        </blockquote>
                    </div>
                </CamaraCard>

                <CamaraCard
                    image="c26_2.jpg"
                    imageAlt="Cámara MOBOTIX&copy; C26 Indoor 360º"
                    imagePosition={CameraCardImagePosition.Left}
                >
                    <div className="pt-6 md:p-8 text-center md:text-left space-y-4">

                        <div className="flex flex-row items-center">
                            <h3 className={`text-2xl font-bold mb-2`}>
                                c26 Indoor 360°
                            </h3>
                        </div>

                        <blockquote>
                            <p className="font-sem whitespace-pre-line">
                                La c26 es el sistema completo de vídeo hemisférico más pequeño y ligero de MOBOTIX, para un montaje rápido en techos de espacios interiores. El equipamiento incluye un sensor de día o de noche sensible a la luz, de 6 MP, y las herramientas de análisis de vídeo MxAnalytics, por ejemplo, para representar los recorridos de los clientes en espacios comerciales.
                            </p>
                        </blockquote>
                    </div>
                </CamaraCard>

            </div>
        </MainLayout>
    )
};

export default CamarasInterioresPage;